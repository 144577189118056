import Vue from 'vue';
import App from './App.vue';
import router from './router';
import 'swiper/css/swiper.min.css';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'wowjs/css/libs/animate.css';

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
