<template>
  <div class="swiper-container swiper-container-v">
    <div class="swiper-wrapper">
      <div class="swiper-slide" ref="firstColumn">
        <img class="logoImg" src="../../img/Logo-白色.png">
        <img class="img3" src="../../img/图层 0.png">
        <img class="img0" src="../../img/图层 3.png">
        <img class="img1" src="../../img/图层 2.png">
        <img class="img2" src="../../img/图层 1.png">
        <img class="img4" src="../../img/图层 4.png">
        <img class="img5" src="../../img/云-左前.png">
        <img class="img6" src="../../img/云-左后.png">
        <img class="img7" src="../../img/云-右前.png">
        <img class="img8" src="../../img/云-右后.png">
        <div class="dotImages" id="dotImages"></div>
        <img class="textImg0"
             src="../../img/助您信息化腾飞 2.png">
        <img class="textImg1"
             src="../../img/以信至信 2.png">
        <img class="textImg2"
             src="../../img/专业从事IT系统规划设计、IT系统建设、数据分析服务 2.png">
      </div>
      <div class="swiper-slide">
        <div class="twoColumnBack">
          <img class="coloursLogo" src="../../img/Logo-彩色.png">
          <div class="contactUsTxt">关于信飞</div>
          <div class="swiper-container swiper-container-h">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <img class="twoColumnImg4" src="../../img/云南.png">
                <img class="twoColumnLogo" src="../../img/logo.png">
                <div class="divCircle1"></div>
                <img class="divBow1" src="../../img/divTow.png">
                <div class="divCircle2"></div>
                <div class="divCircle3"></div>
                <div class="divCircle4"></div>
                <div class="divCircle5"></div>
                <div class="divCircle6"></div>
                <div class="divCircle7"></div>
                <div class="divCircle8"></div>
                <div class="twoColumnImg2">公司介绍</div>
                <div class="twoColumnImg3">昆明信飞科技有限公司是一家专业进行软件研发，数据分析
                  ，系统集成的高新技术企业。重点应用云计算、空间信息、
                  人工智能技术实施政务信息化领域的产品研发。
                </div>
                <div class="swiper-pagination custom-pagination"></div>
              </div>
              <div class="swiper-slide">
                <img class="twoColumnImg5" src="../../img/证书-1.png">
                <img class="twoColumnImg5" src="../../img/证书-2.png">
                <img class="twoColumnImg5" src="../../img/证书-3.png">
                <img class="twoColumnImg5" src="../../img/证书-4.png">
                <img class="twoColumnImg5" src="../../img/证书-5.png">
                <img class="twoColumnImg5" src="../../img/证书-6.png">
                <div class="twoColumnImg2s">资质荣誉</div>
                <div class="twoColumnImg3s">
                  信飞科技连续多年被认定为国家高新技术企业、国家科技型中小企业，
                  并具有其它十余项资质荣誉，成立至今获得3项软件产品认定，
                  拥有60余项计算机软件著作权。公司具有较为完善的治理体系，
                  连续多年通过ISO9001质量体系认证，为昆明市重合同守信用企业。
                </div>
                <div class="swiper-pagination custom-pagination"></div>
              </div>
              <!-- Add Pagination -->
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-slide">
        <div class="threeColumn"></div>
        <div class="solveTxt">解决方案</div>
        <img class="logoImg" src="../../img/Logo-白色.png">
        <div class="threeColumnImg0" @mouseover="threeColumnImgOver('.shapeImg1','.textColumn1', 1)"
             @mouseleave="threeColumnImgLeave('.shapeImg1','.textColumn1', 1)">
          <img class="shapeImg1" src="../../img/形状结合 1-正常.png">
          <div class="textColumn1">
            <img class="svgImg1" src="../../img/01.svg">
            <div class="solveTxt2 solvePlace1">
              数据中台建设
            </div>
            <div class="solveTxt3 solvePlace2">
              深度分析企业（单位）数据现状和业务需求，基于数据库、数据仓库、ETL+流式处理、敏捷BI等技术，为企业建立业务能力可复用的数据中台
            </div>
          </div>
        </div>
        <div class="threeColumnImg1" @mouseover="threeColumnImgOver('.shapeImg2','.textColumn2', 2)"
             @mouseleave="threeColumnImgLeave('.shapeImg2','.textColumn2', 2)">
          <img class="shapeImg2" src="../../img/形状结合 2-正常.png">
          <div class="textColumn2">
            <img class="svgImg2" src="../../img/02.svg">
            <div class="solveTxt2 solvePlace3">企业数字化转型</div>
            <div class="solveTxt3 solvePlace2">
              基于双中台理念，借助云原生、AI、空间信息等技术，为企业（单位）的数字化转型提供整体解决方案
            </div>
          </div>
        </div>
        <div class="threeColumnImg2" @mouseover="threeColumnImgOver('.shapeImg3','.textColumn3', 3)"
             @mouseleave="threeColumnImgLeave('.shapeImg3','.textColumn3', 3)">
          <img class="shapeImg3" src="../../img/形状结合 3-正常.png">
          <div class="textColumn3">
            <img class="svgImg3" src="../../img/03.svg">
            <div class="solveTxt2 solvePlace5">计算机视觉（CV）</div>
            <div class="solveTxt3 solvePlace2">
              基于CV技术，提供可定制的特定业务应用领域的分析算法，为企业（单位）提供智能化解决方案
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-slide">
        <div class="fourColumn">
          <img class="backgroundFour" src="../../img/背景.jpg">
          <img class="coloursLogo" src="../../img/Logo-彩色.png">
          <div class="titleTxt">人才招聘</div>
          <div class="fourColumn2">
            <div class="contactDiv contactDiv0">
              <div class="recruitTxt">IT项目经理</div>
              <div class="recruitTxt2">5年以上工作经验&nbsp;|&nbsp;本科</div>
            </div>
            <div class="contactDiv contactDiv1">
              <div class="recruitTxt">IT基础构架研究员</div>
              <div class="recruitTxt2">5年以上工作经验&nbsp;|&nbsp;本科</div>
            </div>
            <div class="contactDiv contactDiv2">
              <div class="recruitTxt">软件产品经理</div>
              <div class="recruitTxt2">5年以上工作经验&nbsp;|&nbsp;大专</div>
            </div>
            <div class="contactDiv contactDiv3">
              <div class="recruitTxt">软件开发工程师</div>
              <div class="recruitTxt2">1-3年工作经验&nbsp;|&nbsp;大专</div>
            </div>
            <div class="contactDiv contactDiv4">
              <div class="recruitTxt">软件测试工程师</div>
              <div class="recruitTxt2">1-3年工作经验&nbsp;|&nbsp;大专</div>
            </div>
            <div class="contactDiv contactDiv5">
              <div class="recruitTxt">IT技术运维工程师</div>
              <div class="recruitTxt2">1-3年工作经验&nbsp;|&nbsp;大专</div>
            </div>
            <div class="contactDiv6">
              <div class="recruitTxt3">* 具体招聘岗位要求请转至智联招聘或前程无忧查看</div>
              <a target="_blank"
                 href="https://sou.zhaopin.com/?jl=831&kw=%E6%98%86%E6%98%8E%E4%BF%A1%E9%A3%9E%E7%A7%91%E6%8A%80%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8&p=1">
                <div class="recruitTxt4 Zhaopin">前往智联招聘</div>
                <img class="arrowMark1" src="../../img/7.Icon／arrow／18.arrowright.png">
              </a>
              <a target="_blank"
                 href="https://search.51job.com/list/250200,000000,0000,00,9,99,%25E6%2598%2586%25E6%2598%258E%25E4%25BF%25A1%25E9%25A3%259E%25E7%25A7%2591%25E6%258A%2580%25E6%259C%2589%25E9%2599%2590%25E5%2585%25AC%25E5%258F%25B8,2,1.html?lang=c&postchannel=0000&workyear=99&cotype=99&degreefrom=99&jobterm=99&companysize=99&ord_field=0&dibiaoid=0&line=&welfare=">
                <div class="recruitTxt4 prospect">前往前程无忧</div>
                <img class="arrowMark2" src="../../img/7.Icon／arrow／18.arrowright.png">
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-slide">
        <img class="coloursLogo" src="../../img/Logo-彩色.png">
        <div class="atlasDiv">
          <div class="atlasDiv2">
            <a target="_blank"
               href="https://map.baidu.com/search/%E6%98%86%E6%98%8E%E4%BF%A1%E9%A3%9E%E7%A7%91%E6%8A%80%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/@11436725.275,2864109.35,19z?querytype=s&da_src=shareurl&wd=%E6%98%86%E6%98%8E%E4%BF%A1%E9%A3%9E%E7%A7%91%E6%8A%80%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8&c=104&src=0&wd2=%E6%98%86%E6%98%8E%E5%B8%82%E7%9B%98%E9%BE%99%E5%8C%BA&pn=0&sug=1&l=13&b=(11409954,2851449;11471394,2882713)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&sug_forward=c0b68a1fb14d6ba6d1f49e88&seckey=48f747b6a4e9acf8f2520f8ee4d6cf906709597974d758a959c92c58dcd09954c307c714fd7220fd7e33aca1c5da210430e030eebe9da5a99dda8692678d74d8f7bca41a4a5d1f6175200f1f0844c0b13ffb1af10f89583965ba6247e774a64c14d9877179cee54b22bcafdc62d8ce31945b385c98ecd242def8e8d0939c630f5f736742ea76bf74f5f4a1b4455c7b3ac2bcb8b7ff5800a4d729c417316290f4733dd86130ac8894edfa8812b703b78e2a9bc71b1982661a89a13ae5525e9267f5798a455bcff10a4f3dc36b1b2233c8d51cbc1a53950663977f8fa594a0e9d4a033a47776a34a15faa33418ac92a0b6&device_ratio=1">
              <div class="tagSystem"></div>
              <div class="tagSystem1"></div>
              <div class="tagSystem2"></div>
            </a>
            <img class="atlas" src="../../img/截屏2021-07-15 08.49.20.jpg">
          </div>
        </div>
        <div class="fiveColumn">
          <div class="contact">联系我们</div>
          <div class="district">
            <div class="contactWay">联系方式</div>
            <div class="mod1">
              <span class="text0">联系人：冯女士</span>
            </div>
            <div class="mod2">
              <span class="text0">联系电话：0871-63368571-602</span>
            </div>
            <div class="mod3">
              <span class="text0">传真：0871-63368571-601</span>
            </div>
            <div class="mod4">
              <span class="text0">电子邮件：fengdujuan&#64;geofly.com.cn</span>
            </div>
            <div class="mod5">
              <span class="text0">
            地址：昆明市&nbsp;盘龙区&nbsp;白龙路1号&nbsp;金色年华二期&nbsp;6&nbsp;楼
              </span>
            </div>
            <div class="mod6">
              <span class="text0">邮政编码：650216</span>
            </div>
          </div>
          <div class="mod7">
          <span class="text0">
            Copyright&nbsp;©&nbsp;2021&nbsp;昆明信飞科技有限公司&nbsp;版权所有
          </span>
          </div>
          <a target="_blank" href="https://beian.miit.gov.cn">
            <div class="mod8">
              <span class="text0">滇ICP备14003252号-1</span>
            </div>
          </a>
        </div>
      </div>
    </div>
    <img class="menuImg" id="menuImg" @click="openColumn" src="../../img/MENU-白色.png">
    <div class="rightDiv0" ref="openColumn">
      <div class="rightDiv1">
        <div class="rightDiv2">
          <div class="hover"></div>
          <div class="column0" @click="toslide(0, '16.57%')">首页</div>
          <div class="column1" @click="toslide(1, '26.02%')">关于信飞</div>
          <div class="column2" @click="toslide(2, '35.46%')">解决方案</div>
          <div class="column3" @click="toslide(3, '44.91%')">人才招聘</div>
          <div class="column4" @click="toslide(4, '54.35%')">联系我们</div>
        </div>
      </div>
    </div>
    <div class="swiperv-button-next">
      <i></i>
    </div>
    <div class="swiper-pagination swiper-pagination-v"></div>
  </div>
</template>

<script>
import Swiper from 'swiper';
// eslint-disable-next-line import/no-extraneous-dependencies
import { WOW } from 'wowjs';
import $ from 'jquery';
// import * as THREE from 'three';

export default {
  name: 'index',
  data() {
    return {
      swiperIndex: null,
      swiperHIndex: null,
      // 第四屏延时器
      fourSetTime0: '',
      fourSetTime1: '',
      // 第二屏延时器
      twoSetTime0: '',
      twoSetTime1: '',
      twoSetTime2: '',
      twoSetTime3: '',
      twoSetTime4: '',
      twoSetTime5: '',
      twoSetTime6: '',
      // 第三屏延时器
      threeSetTime0: '',
      threeSetTime1: '',
      // 第五屏延时器
      fiveSetTime: '',
      tagSystemSetTime: '',
      // 点阵动画参数
      // SEPARATION: 100,
      // AMOUNTX: 50,
      // AMOUNTY: 50,
      // container: '',
      // camera: '',
      // scene: '',
      // renderer: '',
      // particles: '',
      // particle: '',
      // count: 0,
      // mouseX: 0,
      // mouseY: 0,
      // windowHalfX: '',
      // windowHalfY: '',
    };
  },
  mounted() {
    // eslint-disable-next-line no-underscore-dangle
    const _this = this;
    this.$nextTick(() => {
    });
    this.windowHalfY = window.innerHeight / 2;
    this.windowHalfX = window.innerWidth / 2;
    // 将事件侦听器函数附加到窗口的resize事件
    window.addEventListener('resize', this.watchWindowSize);
    // 第一次调用该函数
    this.watchWindowSize();
    this.imgAfter();
    // swiper竖向结构
    // eslint-disable-next-line no-unused-vars,no-multi-assign
    const swiperV = _this.swiperV = new Swiper('.swiper-container-v', {
      direction: 'vertical',
      spaceBetween: 50,
      mousewheel: true,
      pagination: {
        el: '.swiper-pagination-v',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiperv-button-next',
      },
      on: {
        slideChangeTransitionEnd() {
          // eslint-disable-next-line no-undef
          _this.swiperScreen(this.realIndex);
        },
      },
    });
      // swiper横向结构
      // eslint-disable-next-line no-unused-vars
    const swiperH = new Swiper('.swiper-container-h', {
      // 自动切换
      autoplay: {
        delay: 7000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.custom-pagination',
        clickable: true,
        // autoplayDisableOnInteraction: false,
      },
      on: {
        slideChangeTransitionEnd() {
          _this.swiperHIndex = this.realIndex;
          _this.twoTouch(this.realIndex);
        },
      },
    });
    // this.initDotImages();
    // this.animate();
  },
  methods: {
    // 点阵动画
    // initDotImages() {
    //   this.container = document.getElementById('dotImages');
    //   document.body.appendChild(this.container);
    //   // eslint-disable-next-line no-undef,max-len
    //   // eslint-disable-next-line no-undef
    //   this.camera = new THREE.PerspectiveCamera(75,
    //     window.innerWidth / window.innerHeight, 1, 10000);
    //   this.camera.position.z = 1000;
    //   // eslint-disable-next-line no-undef
    //   this.scene = new THREE.Scene();
    //   // eslint-disable-next-line no-array-constructor
    //   this.particles = new Array();
    //   const PI2 = Math.PI * 2;
    //   // eslint-disable-next-line no-undef
    //   const material = new THREE.Material({
    //     color: 0xffffff,
    //     program(context) {
    //       context.beginPath();
    //       context.arc(0, 0, 1, 0, PI2, true);
    //       context.fill();
    //     },
    //   });
    //   let i = 0;
    //   // eslint-disable-next-line no-plusplus
    //   for (let ix = 0; ix < this.AMOUNTX; ix++) {
    //     // eslint-disable-next-line no-plusplus
    //     for (let iy = 0; iy < this.AMOUNTY; iy++) {
    //       // eslint-disable-next-line no-undef,no-multi-assign,no-plusplus
    //       this.particle = this.particles[i++] = new THREE.Particle(material);
    // eslint-disable-next-line max-len
    //       this.particle.position.x = ix * this.SEPARATION - ((this.AMOUNTX * this.SEPARATION) / 2);
    // eslint-disable-next-line max-len
    //       this.particle.position.z = iy * this.SEPARATION - ((this.AMOUNTY * this.SEPARATION) / 2);
    //       this.scene.add(this.particle);
    //     }
    //   }
    //   // eslint-disable-next-line no-undef
    //   this.renderer = new THREE.CanvasRenderer();
    //   this.renderer.setSize(window.innerWidth, window.innerHeight);
    //   this.container.appendChild(this.renderer.domElement);
    //   document.addEventListener('mousemove', this.onDocumentMouseMove, false);
    //   document.addEventListener('touchstart', this.onDocumentTouchStart, false);
    //   document.addEventListener('touchmove', this.onDocumentTouchMove, false);
    //   window.addEventListener('resize', this.onWindowResize, false);
    // },
    // onDocumentTouchMove(event) {
    //   if (event.touches.length === 1) {
    //     event.preventDefault();
    //     this.mouseX = event.touches[0].pageX - this.windowHalfX;
    //     this.mouseY = event.touches[0].pageY - this.windowHalfY;
    //   }
    // },
    // onWindowResize() {
    //   this.windowHalfX = window.innerWidth / 2;
    //   this.windowHalfY = window.innerHeight / 2;
    //   this.camera.aspect = window.innerWidth / window.innerHeight;
    //   this.camera.updateProjectionMatrix();
    //   this.renderer.setSize(window.innerWidth, window.innerHeight);
    // },
    // onDocumentMouseMove(event) {
    //   this.mouseX = event.clientX - this.windowHalfX;
    //   this.mouseY = event.clientY - this.windowHalfY;
    // },
    // onDocumentTouchStart(event) {
    //   if (event.touches.length === 1) {
    //     event.preventDefault();
    //     this.mouseX = event.touches[0].pageX - this.windowHalfX;
    //     this.mouseY = event.touches[0].pageY - this.windowHalfY;
    //   }
    // },
    // animate() {
    //   requestAnimationFrame(this.animate);
    //   this.render();
    // },
    // render() {
    //   this.camera.position.x += (this.mouseX - this.camera.position.x) * 0.5;
    //   this.camera.position.y += (-this.mouseY - this.camera.position.y) * 0.5;
    //   this.camera.lookAt(this.scene.position);
    //   let i = 0;
    //   // eslint-disable-next-line no-plusplus
    //   for (let ix = 0; ix < this.AMOUNTX; ix++) {
    //     // eslint-disable-next-line no-plusplus
    //     for (let iy = 0; iy < this.AMOUNTY; iy++) {
    //       // eslint-disable-next-line no-plusplus
    //       this.particle = this.particles[i++];
    //       // eslint-disable-next-line max-len
    // eslint-disable-next-line max-len
    //       this.particle.position.y = (Math.sin((ix + this.count) * 0.3) * 50) + (Math.sin((iy + this.count) * 0.5) * 50);
    //       // eslint-disable-next-line max-len,no-multi-assign
    // eslint-disable-next-line max-len
    //       this.particle.scale.x = this.particle.scale.y = (Math.sin((ix + this.count) * 0.3) + 1) * 2 + (Math.sin((iy + this.count) * 0.5) + 1) * 2;
    //     }
    //   }
    //   this.renderer.render(this.scene, this.camera);
    //   this.count += 0.1;
    // },
    // 第四屏切屏动画
    fourColumnA() {
      $('.titleTxt')
        .css({
          transition: 'all 0.6s ease-in-out',
          top: '13.89%',
          opacity: 1,
        });
      this.fourSetTime0 = setTimeout(() => {
        $('.fourColumn2')
          .css({
            transition: 'all 0.8s ease-in-out',
            top: '25.93%',
            opacity: 1,
          });
      }, 600);
    },
    watchWindowSize() {
      // 获取窗口的宽度和高度，不包括滚动条
      const w = document.documentElement.clientWidth;
      const h = document.documentElement.clientHeight;
      const scaleW = w / 1920;
      if (w >= 1440 && h >= 780) {
        $('.atlas')
          .css({
            transform: `scale(${(1 - scaleW) + 1})`,
          });
      }
    },
    fourColumnB() {
      clearTimeout(this.fourSetTime0);
      $('.titleTxt')
        .css({
          transition: 'all 0s linear',
          top: '23.89%',
          opacity: 0,
        });
      $('.fourColumn2')
        .css({
          transition: 'all 0s ease-in-out',
          top: '55.93%',
          opacity: 0,
        });
    },
    // 第五屏动画
    fiveCart() {
      $('.atlasDiv ')
        .css({
          transition: 'all 0.6s ease-in-out',
          left: 0,
        });
      $('.contact')
        .css({
          transition: 'all 0.4s ease-in-out',
          top: '13.89%',
          opacity: 1,
        });
      this.fiveSetTime = setTimeout(() => {
        $('.tagSystem')
          .css({
            transition: 'all 0.6s ease-in-out',
            opacity: 1,
            top: 0,
          });
        $('.district')
          .css({
            transition: 'all 0.4s ease-in-out',
            opacity: 1,
            top: '58.33%',
          });
      }, 800);
      this.tagSystemSetTime = setTimeout(() => {
        $('.tagSystem1')
          .css({
            transition: 'all 0.3s ease-in-out',
            animation: 'ddd 1s linear infinite',
            opacity: 1,
          });
        $('.tagSystem2')
          .css({
            transition: 'all 0.3s ease-in-out',
            animation: 'ddd 1s linear infinite',
            opacity: 1,
          });
      }, 1400);
    },
    fiveCartB() {
      clearTimeout(this.fiveSetTime);
      clearTimeout(this.tagSystemSetTime);
      $('.atlasDiv ')
        .css({
          transition: 'all 0 ease-in-out',
          left: '-20%',
        });
      $('.tagSystem1')
        .css({
          transition: 'all 0s ease-in-out',
          animation: '',
          opacity: 0,
        });
      $('.tagSystem2')
        .css({
          transition: 'all 0s ease-in-out',
          animation: '',
          opacity: 0,
        });
      $('.district')
        .css({
          transition: 'all 0s linear',
          opacity: 0,
          top: '78.33%',
        });
      $('.contact')
        .css({
          transition: 'all 0s linear',
          top: '23.89%',
          opacity: 0,
        });
      $('.tagSystem')
        .css({
          transition: 'all 0s linear',
          opacity: 0,
          top: '-20%',
        });
    },
    // 第二栏横向切屏触发事件
    twoTouch(index) {
      if (index === 0) {
        this.twoMoveA();
      } else {
        this.twoMoveA2();
      }
    },
    // 第二栏第一页动画
    twoMoveA() {
      $('.contactUsTxt')
        .css({
          transition: 'all 0.6s ease-in-out',
          opacity: 1,
          top: '13.89%',
        });
      this.twoSetTime5 = setTimeout(() => {
        $('.swiper-container-h .swiper-pagination-bullets')
          .css({
            transition: 'all 0s ease-in-out',
            opacity: 0,
            top: '260px',
          });
      }, 7000);
      this.twoSetTime0 = setTimeout(() => {
        $('.twoColumnImg2')
          .css({
            transition: 'all 0.6s ease-in-out',
            opacity: 1,
            top: '160px',
          });
        $('.twoColumnImg3')
          .css({
            transition: 'all 0.8s ease-in-out',
            opacity: 1,
            top: '262px',
          });
        $('.twoColumnImg4')
          .css({
            transition: 'all 0.6s ease-in-out',
            opacity: 1,
            top: 0,
          });
        $('.swiper-container-h .swiper-pagination-bullets')
          .css({
            transition: 'all 0.6s ease-in-out',
            opacity: 1,
            top: '160px',
          });
      }, 600);
      this.twoSetTime1 = setTimeout(() => {
        $('.twoColumnLogo')
          .css({
            transition: 'all 0.3s ease-in-out',
            opacity: 1,
            top: '238px',
          });
      }, 1400);
      this.twoSetTime2 = setTimeout(() => {
        $('.divBow1')
          .css({
            transition: 'all 0.6s ease-in-out',
            opacity: 1,
          });
      }, 1700);
      this.twoSetTime3 = setTimeout(() => {
        $('.divCircle1')
          .css({
            transition: 'all 0.1s ease-in-out',
            animation: 'dddd 0.6s linear alternate',
            opacity: 1,
          });
        $('.divCircle2')
          .css({
            transition: 'all 1.1s ease-in-out',
            animation: 'dddd 0.6s linear alternate',
            opacity: 1,
          });
        $('.divCircle3')
          .css({
            transition: 'all 0.3s ease-in-out',
            animation: 'dddd 0.6s linear alternate',
            opacity: 1,
          });
        $('.divCircle4')
          .css({
            transition: 'all 0.9s ease-in-out',
            animation: 'dddd 0.6s linear alternate',
            opacity: 1,
          });
        $('.divCircle5')
          .css({
            transition: 'all 0.5s ease-in-out',
            animation: 'dddd 0.6s linear alternate',
            opacity: 1,
          });
        $('.divCircle6')
          .css({
            transition: 'all 1.3s ease-in-out',
            animation: 'dddd 0.6s linear alternate',
            opacity: 1,
          });
        $('.divCircle7')
          .css({
            transition: 'all 0.7s ease-in-out',
            animation: 'dddd 0.6s linear alternate',
            opacity: 1,
          });
        $('.divCircle8')
          .css({
            transition: 'all 1.5s linease-in-outear',
            animation: 'dddd 0.6s linear alternate',
            opacity: 1,
          });
      }, 2000);
      $('.twoColumnImg2s')
        .css({
          transition: 'all 0s linear',
          opacity: 0,
          left: '820px',
        });
      $('.twoColumnImg3s')
        .css({
          transition: 'all 0s linear',
          opacity: 0,
          left: '820px',
        });
      $('.twoColumnImg5')
        .css({
          transition: 'all 0s linear',
          opacity: 0,
          left: '20%',
        });
      clearTimeout(this.twoSetTime4);
      clearTimeout(this.twoSetTime6);
    },
    twoMoveB() {
      clearTimeout(this.twoSetTime0);
      clearTimeout(this.twoSetTime1);
      clearTimeout(this.twoSetTime2);
      clearTimeout(this.twoSetTime3);
      clearTimeout(this.twoSetTime4);
      clearTimeout(this.twoSetTime5);
      clearTimeout(this.twoSetTime6);
      $('.divCircle1')
        .css({
          transition: 'all 0s linear',
          animation: '',
          opacity: 0,
        });
      $('.divCircle2')
        .css({
          transition: 'all 0s linear',
          animation: '',
          opacity: 0,
        });
      $('.divCircle3')
        .css({
          transition: 'all 0s linear',
          opacity: 0,
        });
      $('.divCircle4')
        .css({
          transition: 'all 0s linear',
          animation: '',
          opacity: 0,
        });
      $('.divCircle5')
        .css({
          transition: 'all 0s linear',
          animation: '',
          opacity: 0,
        });
      $('.divCircle6')
        .css({
          transition: 'all 0s linear',
          animation: '',
          opacity: 0,
        });
      $('.divCircle7')
        .css({
          transition: 'all 0s linear',
          animation: '',
          opacity: 0,
        });
      $('.divCircle8')
        .css({
          transition: 'all 0s linear',
          animation: '',
          opacity: 0,
        });
      $('.twoColumnLogo')
        .css({
          transition: 'all 0s linear',
          opacity: 0,
          top: '138px',
        });
      $('.contactUsTxt')
        .css({
          transition: 'all 0s linear',
          opacity: 0,
          top: '23.89%',
        });
      $('.twoColumnImg2')
        .css({
          transition: 'all 0s linear',
          opacity: 0,
          top: '260px',
        });
      $('.twoColumnImg3')
        .css({
          transition: 'all 0s linear',
          opacity: 0,
          top: '362px',
        });
      $('.twoColumnImg4')
        .css({
          transition: 'all 0s linear',
          opacity: 0,
          top: '30%',
        });
      $('.swiper-container-h .swiper-pagination-bullets')
        .css({
          transition: 'all 0s linear',
          opacity: 0,
          top: '260px',
        });
      $('.twoColumnImg2s')
        .css({
          transition: 'all 0s linear',
          opacity: 0,
          left: '820px',
        });
      $('.twoColumnImg3s')
        .css({
          transition: 'all 0s linear',
          opacity: 0,
          left: '820px',
        });
      $('.twoColumnImg5')
        .css({
          transition: 'all 0s linear',
          opacity: 0,
          left: '20%',
        });
      $('.divBow1')
        .css({
          transition: 'all 0s linear',
          opacity: 0,
        });
    },
    // 第二栏第二页触发动画
    twoMoveA2() {
      this.twoSetTime6 = setTimeout(() => {
        $('.swiper-container-h .swiper-pagination-bullets')
          .css({
            transition: 'all 0s ease-in-out',
            opacity: 0,
            top: '260px',
          });
      }, 7000);
      $('.contactUsTxt')
        .css({
          transition: 'all 0.6s ease-in-out',
          opacity: 1,
          top: '13.89%',
        });
      this.twoSetTime4 = setTimeout(() => {
        $('.twoColumnImg2s')
          .css({
            transition: 'all 0.7s ease-in-out',
            opacity: 1,
            left: '720px',
          });
        $('.twoColumnImg3s')
          .css({
            transition: 'all 0.8s ease-in-out',
            opacity: 1,
            left: '720px',
          });
        $('.twoColumnImg5')
          .css({
            transition: 'all 0.6s ease-in-out',
            opacity: 1,
            left: 0,
          });
        $('.swiper-container-h .swiper-pagination-bullets')
          .css({
            transition: 'all 0.7s ease-in-out',
            opacity: 1,
            top: '160px',
          });
      }, 600);
      $('.twoColumnImg2')
        .css({
          transition: 'all 0s linear',
          opacity: 0,
          top: '260px',
        });
      $('.twoColumnImg3')
        .css({
          transition: 'all 0s linear',
          opacity: 0,
          top: '362px',
        });
      $('.twoColumnImg4')
        .css({
          transition: 'all 0s linear',
          opacity: 0,
          top: '30%',
        });
      $('.twoColumnLogo')
        .css({
          transition: 'all 0s linear',
          opacity: 0,
          top: '138px',
        });
      $('.divBow1')
        .css({
          transition: 'all 0s linear',
          opacity: 0,
        });
      $('.divCircle1')
        .css({
          transition: 'all 0s linear',
          animation: '',
          opacity: 0,
        });
      $('.divCircle2')
        .css({
          transition: 'all 0s linear',
          animation: '',
          opacity: 0,
        });
      $('.divCircle3')
        .css({
          transition: 'all 0s linear',
          animation: '',
          opacity: 0,
        });
      $('.divCircle4')
        .css({
          transition: 'all 0s linear',
          animation: '',
          opacity: 0,
        });
      $('.divCircle5')
        .css({
          transition: 'all 0s linear',
          animation: '',
          opacity: 0,
        });
      $('.divCircle6')
        .css({
          transition: 'all 0s linear',
          animation: '',
          opacity: 0,
        });
      $('.divCircle7')
        .css({
          transition: 'all 0s linear',
          animation: '',
          opacity: 0,
        });
      $('.divCircle8')
        .css({
          transition: 'all 0s linear',
          animation: '',
          opacity: 0,
        });
      clearTimeout(this.twoSetTime0);
      clearTimeout(this.twoSetTime1);
      clearTimeout(this.twoSetTime2);
      clearTimeout(this.twoSetTime3);
      clearTimeout(this.twoSetTime5);
    },
    // 第三屏鼠标移入激活方法
    threeColumnImgOver(class1, class2, index) {
      if (index === 1) {
        // eslint-disable-next-line global-require,no-undef
        $(class1)
        // eslint-disable-next-line global-require
          .prop('src', require('../../img/形状结合 1-激活.png'));
      } else if (index === 2) {
        // eslint-disable-next-line global-require,no-undef
        $(class1)
        // eslint-disable-next-line global-require
          .prop('src', require('../../img/形状结合 2-激活.png'));
      } else {
        // eslint-disable-next-line global-require,no-undef
        $(class1)
        // eslint-disable-next-line global-require
          .prop('src', require('../../img/形状结合 3-激活.png'));
      }
      $(class2)
        .css('transition', 'all 0.3s linear')
        .css('top', '32.8%');
    },
    // 第三屏鼠标移出恢复方法
    threeColumnImgLeave(class1, class2, index) {
      if (index === 1) {
        $(class1)
        // eslint-disable-next-line global-require,no-undef
          .prop('src', require('../../img/形状结合 1-正常.png'));
      } else if (index === 2) {
        $(class1)
        // eslint-disable-next-line global-require
          .prop('src', require('../../img/形状结合 2-正常.png'));
      } else {
        $(class1)
        // eslint-disable-next-line global-require
          .prop('src', require('../../img/形状结合 3-正常.png'));
      }
      $(class2)
        .css('transition', 'all 0.3s linear')
        .css('top', '34.8%');
    },
    // 大山移动后的高度
    imgAfter() {
      $('.img2')
        .css('transition', 'all 2.5s ease-in-out')
        .css('height', '81.2%');
      $('.img1')
        .css('transition', 'all 2.5s ease-in-out')
        .css('height', '75.83%');
      $('.img0')
        .css('transition', 'all 2.5s ease-in-out')
        .css('height', '67.22%');
      $('.img3')
        .css('transition', 'all 2.5s ease-in-out')
        .css('height', '40.74%');
      $('.img5')
        .css({
          transition: 'all 60s linear',
          left: '100%',
        });
      $('.img6')
        .css({
          transition: 'all 70s linear',
          left: '100%',
        });
      $('.img7')
        .css({
          transition: 'all 50s linear',
          right: '100%',
        });
      $('.img8')
        .css({
          transition: 'all 80s linear',
          right: '100%',
        });
      $('.textImg1')
        .css({
          transition: 'all 1.0s ease-in-out',
          top: '22.41%',
        });
      $('.textImg0')
        .css({
          transition: 'all 1.4s ease-in-out',
          top: '38.15%',
        });
      $('.textImg2')
        .css({
          transition: 'all 1.8s ease-in-out',
          top: '53.15%',
        });
    },
    // 大山的初始
    imgBefore() {
      $('.img5')
        .css({
          transition: 'all 0s ease-in-out',
          left: '-36.46%',
        });
      $('.img6')
        .css({
          transition: 'all 0s ease-in-out',
          left: '-13.49%',
        });
      $('.img7')
        .css({
          transition: 'all 0s ease-in-out',
          right: '-25.73%',
        });
      $('.img8')
        .css({
          transition: 'all 0s ease-in-out',
          right: '-30.57%',
        });
      $('.img3')
        .css('transition', 'all 0s linear')
        .css('height', '60.74%');
      $('.img2')
        .css('transition', 'all 0s linear')
        .css('height', '62.2%');
      $('.img1')
        .css('transition', 'all 0s linear')
        .css('height', '60.83%');
      $('.img0')
        .css('transition', 'all 0s linear')
        .css('height', '54.22%');
      $('.textImg0')
        .css({
          transition: 'all 0s linear',
          top: '68.15%',
        });
      $('.textImg1')
        .css({
          transition: 'all 0s linear',
          top: '52.41%',
        });
      $('.textImg2')
        .css({
          transition: 'all 0s linear',
          top: '83.15%',
        });
    },
    // 第三栏图片位置初始化
    threeColumnImgBefore() {
      clearTimeout(this.threeSetTime0);
      clearTimeout(this.threeSetTime1);
      $('.threeColumnImg0')
        .css('transition', 'all 0s linear')
        .css('left', '100%');
      $('.threeColumnImg1')
        .css('transition', 'all 0s linear')
        .css('left', '100%');
      $('.threeColumnImg2')
        .css('transition', 'all 0s linear')
        .css('left', '100%');
      $('.solveTxt')
        .css({
          transition: 'all 0.3s linear',
          opacity: 0,
          top: '18%',
        });
      $('.threeColumn')
        .css({
          transition: 'all 0.3s linear',
          height: 0,
          width: 0,
          left: '50%',
          bottom: '10%',
        });
      $('.textColumn1')
        .css({
          transition: 'all 0s linear',
          opacity: 0,
          top: '38.8%',
        });
      $('.textColumn2')
        .css({
          transition: 'all 0s linear',
          opacity: 0,
          top: '38.8%',
        });
      $('.textColumn3')
        .css({
          transition: 'all 0s linear',
          opacity: 0,
          top: '38.8%',
        });
    },
    // 第三栏图片移动后的位置
    threeColumnImgAfter() {
      $('.threeColumn')
        .css({
          transition: 'all 0.8s ease-in-out',
          height: '180vw',
          bottom: '-90vw',
          width: '180vw',
          left: '-40vw',
        });
      this.threeSetTime0 = setTimeout(() => {
        $('.threeColumnImg0')
          .css('transition', 'all 0.8s ease-in-out')
          .css('left', '0');
        $('.threeColumnImg1')
          .css('transition', 'all 0.6s ease-in-out')
          .css('left', '31.25%');
        $('.threeColumnImg2')
          .css('transition', 'all 0.4s ease-in-out')
          .css('left', '64.58%');
        $('.solveTxt')
          .css('transition', 'all 0.6s ease-in-out')
          .css('opacity', '1')
          .css('top', '13.89%');
      }, 800);
      this.threeSetTime1 = setTimeout(() => {
        $('.threeColumn')
          .css('transition', 'all 0.3s ease-in-out')
          .css({
            height: 0,
            width: 0,
            left: '50%',
            bottom: '10%',
          });
        $('.textColumn1')
          .css({
            transition: 'all 0.6s ease-in-out',
            opacity: 1,
            top: '33.8%',
          });
        $('.textColumn2')
          .css({
            transition: 'all 0.6s ease-in-out',
            opacity: 1,
            top: '33.8%',
          });
        $('.textColumn3')
          .css({
            transition: 'all 0.6s ease-in-out',
            opacity: 1,
            top: '33.8%',
          });
      }, 1600);
    },
    // 切换到对应分页
    toslide(index, topNum) {
      this.swiperV.slideTo(index, 500, false);
      $('.hover')
        .css('top', topNum);
      this.openColumn();
      this.swiperScreen(index);
    },
    // 滚动屏幕所触发事件
    swiperScreen(index) {
      this.swiperIndex = index;
      switch (index) {
        case 0:
          // _this.inerLocation();
          this.$nextTick(() => {
            // 在dom渲染完后,再执行动画
            const wow = new WOW({
              live: false,
            });
            wow.init();
          });
          // eslint-disable-next-line global-require
          $('#menuImg')
          // eslint-disable-next-line global-require
            .prop('src', require('../../img/MENU-白色.png'));
          $('.swiper-container-v .swiper-pagination-bullet')
            .css('background', '#FFFFFF');
          $('.swiper-container-v')
            .removeClass('blueBorder')
            .removeClass('blueBorder2')
            .removeClass('blueBorder3');
          $('.hover')
            .css('top', '16.57%');
          this.imgAfter();
          this.threeColumnImgBefore();
          this.twoMoveB();
          this.fiveCartB();
          this.fourColumnB();
          break;
        case 1:
          // eslint-disable-next-line global-require
          $('#menuImg')
          // eslint-disable-next-line global-require
            .prop('src', require('../../img/MENU-蓝色.png'));
          $('.swiper-container-v .swiper-pagination-bullet')
            .css('background', '#143C78');
          $('.swiper-container-v')
            .addClass('blueBorder')
            .addClass('blueBorder2')
            .removeClass('blueBorder3');
          $('.hover')
            .css('top', '26.02%');
          this.imgBefore();
          this.threeColumnImgBefore();
          if (this.swiperHIndex === 1) {
            this.twoMoveA2();
          } else {
            this.twoMoveA();
          }
          this.fiveCartB();
          this.fourColumnB();
          break;
        case 2:
          // eslint-disable-next-line global-require
          $('#menuImg')
          // eslint-disable-next-line global-require
            .prop('src', require('../../img/MENU-白色.png'));
          $('.swiper-container-v .swiper-pagination-bullet')
            .css('background', '#FFFFFF');
          $('.swiper-container-v')
            .removeClass('blueBorder')
            .removeClass('blueBorder2')
            .removeClass('blueBorder3');
          $('.hover')
            .css('top', '35.46%');
          this.imgBefore();
          this.threeColumnImgAfter();
          this.twoMoveB();
          this.fiveCartB();
          this.fourColumnB();
          break;
        case 3:
          // eslint-disable-next-line global-require
          $('#menuImg')
          // eslint-disable-next-line global-require
            .prop('src', require('../../img/MENU-白色.png'));
          $('.swiper-container-v .swiper-pagination-bullet')
            .css('background', '#FFFFFF');
          $('.swiper-container-v')
            .removeClass('blueBorder')
            .addClass('blueBorder2')
            .removeClass('blueBorder3');
          $('.hover')
            .css('top', '44.91%');
          this.imgBefore();
          this.threeColumnImgBefore();
          this.twoMoveB();
          this.fiveCartB();
          this.fourColumnA();
          break;
        default:
          // eslint-disable-next-line global-require
          $('#menuImg')
          // eslint-disable-next-line global-require
            .prop('src', require('../../img/MENU-白色.png'));
          $('.swiper-container-v .swiper-pagination-bullet')
            .css('background', '#FFFFFF');
          $('.swiper-container-v')
            .removeClass('blueBorder')
            .removeClass('blueBorder2')
            .addClass('blueBorder3');
          $('.hover')
            .css('top', '54.35%');
          this.imgBefore();
          this.threeColumnImgBefore();
          this.twoMoveB();
          this.fiveCart();
          this.fourColumnB();
      }
    },
    // 栏目弹窗调用
    openColumn() {
      // eslint-disable-next-line radix
      const rig = parseInt($('.rightDiv0')
        .css('right'));
      if (rig < 0) {
        $('#menuImg')
        // eslint-disable-next-line global-require
          .prop('src', require('../../img/关闭-白色.png'));
        this.moveColumn('.rightDiv0');
        this.moveColumn('.rightDiv1');
        this.moveColumn('.rightDiv2');
      } else {
        if (this.swiperIndex === 1) {
          // eslint-disable-next-line global-require
          $('#menuImg')
          // eslint-disable-next-line global-require
            .prop('src', require('../../img/MENU-蓝色.png'));
        } else {
          // eslint-disable-next-line global-require
          $('#menuImg')
          // eslint-disable-next-line global-require
            .prop('src', require('../../img/MENU-白色.png'));
        }
        this.moveColumnRight('.rightDiv0', 920);
        this.moveColumnRight('.rightDiv1', 840);
        this.moveColumnRight('.rightDiv2', 760);
      }
    },
    // 栏目弹窗弹出
    moveColumn(className) {
      // eslint-disable-next-line radix
      const test1 = parseInt($(className)
        .css('right'));
      console.log(test1);
      const realTimeClData = setInterval(() => {
        // eslint-disable-next-line radix,no-unused-vars
        let test2 = parseInt($(className)
          .css('right'));
        test2 += 10;
        $(className)
          .css('right', `${test2}px`);
        if (test2 >= 0) {
          $(className)
            .css('right', 0);
          clearInterval(realTimeClData);
        }
      }, 5);
    },
    // 栏目弹窗收起
    moveColumnRight(className, distance) {
      // eslint-disable-next-line radix
      const test1 = parseInt($(className)
        .css('right'));
      const realTimeClData = setInterval(() => {
        // eslint-disable-next-line radix,no-unused-vars
        let test2 = parseInt($(className)
          .css('right'));
        test2 -= 10;
        $(className)
          .css('right', `${test2}px`);
        if ((test1 - test2) >= distance || test2 <= -distance) {
          clearInterval(realTimeClData);
        }
      }, 5);
    },
  },
};
</script>
<style>
  .swiper-container-h .swiper-pagination-bullets {
    top: 160px;
    left: 1106px;
    opacity: 0;
  }

  .swiper-container-h .custom-pagination .swiper-pagination-bullet {
    font-size: initial;
    width: 60px !important;
    height: 2px !important;
    background: rgba(20, 60, 120, 0.3) !important;
    opacity: initial;
    margin: 0 !important;
    border-radius: initial;
    margin-left: 24px !important;
    position: initial;
    z-index: 15;
  }

  .swiper-container-h .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #143C78 !important;
  }

  .swiper-container-v .swiperv-button-next {
    position: absolute;
    width: 48px;
    height: 48px;
    border: 2px solid #FFFFFF;
    border-radius: 50%;
    cursor: pointer;
    left: 50%;
    bottom: 12vh;
    z-index: 666;
    outline: none;
    position: relative;
  }

  .swiper-container-v.blueBorder2 .swiperv-button-next {
    border: 2px solid #143C78;
  }

  .swiper-container-v.blueBorder3 .swiperv-button-next {
    visibility: hidden;
  }

  .swiper-container-v .swiperv-button-next i {
    cursor: pointer;
    display: block;
    width: 100%;
    height: 100%;
    background: url("../../img/7.Icon／arrow／4.down.png") no-repeat 12px center;
    animation: xiala 0.6s linear infinite;
  }

  .swiper-container-v.blueBorder2 .swiperv-button-next i {
    background: url("../../img/7.Icon／arrow／4.downblue.png") no-repeat 12px center !important;
  }

  .swiper-container-v.blueBorder3 .swiperv-button-next i {
    visibility: hidden;
  }

  .swiper-container-v .swiperv-button-next:after {
    display: block;
    content: '';
    width: 72px;
    height: 72px;
    border: 1px dashed rgba(255, 255, 255, 0.8);
    position: absolute;
    left: -12px;
    top: -12px;
    border-radius: 50%;
    animation: ddd 1s linear infinite;
  }

  .swiper-container-v.blueBorder2 .swiperv-button-next:after {
    border: 1px dashed #143C78;
  }

  .swiper-container-v.blueBorder3 .swiperv-button-next:after {
    visibility: hidden;
  }

  .swiper-container-v .swiperv-button-next:before {
    animation: ddd 1s linear infinite;
    display: block;
    content: '';
    width: 96px;
    height: 96px;
    border: 1px dashed rgba(255, 255, 255, 0.8);
    position: absolute;
    left: -24px;
    top: -24px;
    border-radius: 50%;
  }

  .swiper-container-v.blueBorder2 .swiperv-button-next:before {
    border: 1px dashed #143C78;
  }

  .swiper-container-v.blueBorder3 .swiperv-button-next:before {
    visibility: hidden;
  }

  .swiper-container-v .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    background: #FFFFFF;
    opacity: 1;
    margin: 80px 0 !important;
    position: relative;
  }

  .swiper-container-v .swiper-pagination-bullet.swiper-pagination-bullet-active:after {
    display: block;
    content: '';
    width: 72px;
    height: 72px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    position: absolute;
    left: -36px;
    top: -36px;
  }

  .swiper-container-v .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
    display: block;
    content: '';
    width: 73px;
    height: 73px;
    border-left: 2px solid #FFFFFF;
    border-radius: 50% 0 0 50%;
    animation: turn 2s linear infinite;
    position: absolute;
    left: -35px;
    top: -35px;
  }

  .swiper-container-h .swiper-pagination-bullet.swiper-pagination-bullet-active:after {
    all: initial;
  }

  .swiper-container-h .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
    all: initial;
  }

  @keyframes ddd {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(1.2);
      opacity: 0;
    }
  }

  @keyframes dddd {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes turn {
    0% {
      -webkit-transform: rotate(0deg);
    }
    25% {
      -webkit-transform: rotate(90deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
    }
    75% {
      -webkit-transform: rotate(270deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes xiala {
    0% {
      background: url("../../img/7.Icon／arrow／4.down.png") no-repeat 12px 3px;
      opacity: 0;
    }
    100% {
      background: url("../../img/7.Icon／arrow／4.down.png") no-repeat 12px 12px;
      opacity: 1;
    }
  }

  .swiper-container-v.blueBorder .swiper-pagination-bullet.swiper-pagination-bullet-active:after {
    border: 2px solid rgba(20, 60, 120, 0.2);
  }

  .swiper-container-v.blueBorder .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
    border-left: 2px solid #143C78;
  }

  .swiper-container {
    position: relative;
    width: 100vw !important;
    height: 100vh !important;
    min-width: 1440px;
    min-height: 780px;
  }

  .swiper-container-v {
    background-color: #FFFFFF;
    width: 100%;
    height: 100%;
    z-index: 4;
  }

  .swiper-container-h .swiper-wrapper {
    top: 22.22vh;
    margin: 0 auto;
    width: 1280px !important;
    height: 600px !important;
    z-index: 10;
  }

  .swiper-container-h .swiper-wrapper .swiper-slide {
    width: 100%;
    height: 100%;
    line-height: 100%;
    position: relative;
    margin: 0px;
  }

  .swiper-wrapper .swiper-slide .img0 {
    width: 100%;
    height: 54.22%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 20;
    transition: all 2s linear;
  }

  .swiper-slide .img1 {
    height: 60.83%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0px;
    margin-bottom: 0px;
    z-index: 15;
    transition: all 2s linear;
  }

  .swiper-slide .img2 {
    height: 62.2%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    transition: all 2s linear;
  }

  .swiper-slide .img4 {
    width: 100%;
    height: 35.46%;
    position: absolute;
    bottom: 0px;
    right: 0;
    z-index: 25;
  }

  .swiper-slide .img3 {
    width: 100%;
    height: 60.74%;
    position: absolute;
    top: 0px;
    left: 0;
    z-index: 5;
  }

  .swiper-slide .img5 {
    position: absolute;
    height: 36.94%;
    width: 36.46%;
    top: 46.39%;
    left: -36.46%;
    z-index: 23;
  }

  .swiper-slide .img6 {
    height: 10.74%;
    width: 13.49%;
    position: absolute;
    top: 26.11%;
    left: -13.49%;
    z-index: 13;
  }

  .swiper-slide .img7 {
    width: 25.73%;
    height: 21.94%;
    position: absolute;
    top: 44.54%;
    right: -25.73%;
    z-index: 23;
  }

  .swiper-slide .img8 {
    width: 30.57%;
    height: 32.59%;
    position: absolute;
    top: 37.69%;
    right: -30.57%;
    z-index: 18;
  }

  .swiper-slide .textImg0 {
    position: absolute;
    width: 1120px;
    height: 172px;
    top: 68.15%;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 18;
  }

  .swiper-slide .textImg1 {
    position: absolute;
    top: 52.41%;
    width: 1120px;
    height: 172px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 13;
  }

  .swiper-slide .textImg2 {
    position: absolute;
    width: 1120px;
    height: 63px;
    top: 83.15%;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 22;
  }

  .swiper-container .menuImg {
    position: absolute;
    cursor: pointer;
    width: 100px;
    height: 30px;
    top: 2.78%;
    right: 2.6%;
    z-index: 45;
  }

  .swiper-slide .logoImg {
    width: 306px;
    height: 50px;
    position: absolute;
    top: 2.78%;
    left: 2.6%;
    z-index: 200;
  }

  .swiper-container .rightDiv0 {
    position: absolute;
    top: 0;
    right: -47.92%;
    width: 47.92%;
    height: 100%;
    background-image: url("../../img/arc-3.png");
    background-repeat: no-repeat;
    z-index: 40;
  }

  .swiper-container .rightDiv1 {
    position: absolute;
    top: 0;
    right: -43.75%;
    width: 91.3%;
    height: 100%;
    z-index: 41;
    background-image: url("../../img/arc-2.png");
    background-repeat: no-repeat;
  }

  .swiper-container .rightDiv2 {
    position: absolute;
    top: 0;
    right: -39.58%;
    width: 90.48%;
    height: 100%;
    z-index: 42;
    background-image: url("../../img/arc-1.png");
    background-repeat: no-repeat;
    color: #FFFFFF;
    font-size: 30px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 38px;
  }

  .column0 {
    position: absolute;
    top: 14.81%;
    left: 57.89%;
    cursor: pointer;
    transition: all 0.2s linear;
  }

  .column0:hover {
    left: 59.5%;
  }

  .column1 {
    position: absolute;
    top: 24.26%;
    left: 57.89%;
    cursor: pointer;
    transition: all 0.2s linear;
  }

  .column1:hover {
    left: 59.5%;
  }

  .column2 {
    position: absolute;
    top: 33.7%;
    left: 57.89%;
    cursor: pointer;
    transition: all 0.2s linear;
  }

  .column2:hover {
    left: 59.5%;;
  }

  .column3 {
    position: absolute;
    top: 43.15%;
    left: 57.89%;
    cursor: pointer;
    transition: all 0.2s linear;
  }

  .column3:hover {
    left: 59.5%;
  }

  .column4 {
    position: absolute;
    top: 52.59%;
    left: 57.89%;
    cursor: pointer;
    transition: all 0.2s linear;
  }

  .column4:hover {
    left: 59.5%;;
  }

  .fiveColumn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #143C78;
    z-index: 5;
  }

  .atlas {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 6;
  }

  .tagSystem {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 60px;
    height: 60px;
    background: url("../../img/标识.svg") no-repeat;
    z-index: 15;
    opacity: 0;
  }

  .tagSystem1 {
    display: block;
    content: '';
    width: 72px;
    height: 72px;
    border: 1px dashed rgba(20, 60, 120, 0.8);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
    z-index: 15;
    opacity: 0;
  }

  .tagSystem2 {
    display: block;
    content: '';
    width: 104px;
    height: 104px;
    border: 1px dashed rgba(20, 60, 120, 0.8);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 15;
    border-radius: 50%;
    opacity: 0;
  }

  .contact {
    position: absolute;
    top: 23.89%;
    left: 50%;
    z-index: 10;
    width: 160px;
    height: 56px;
    font-size: 40px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 56px;
    opacity: 0;
  }

  .contactWay {
    z-index: 10;
    margin-top: 10px;
    width: 120px;
    height: 12.59%;
    font-size: 30px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 38px;
  }

  .text0 {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.65);
    line-height: 9.93%;
  }

  .mod1 {
    z-index: 10;
    margin-top: 24px;
  }

  .mod2 {
    z-index: 10;
    margin-top: 8px;
  }

  .mod3 {
    z-index: 10;
    margin-top: 8px;
  }

  .mod4 {
    z-index: 10;
    margin-top: 8px;
  }

  .mod5 {
    z-index: 10;
    margin-top: 8px;
  }

  .mod6 {
    z-index: 10;
    margin-top: 8px;
  }

  .mod7 {
    position: absolute;
    z-index: 10;
    top: 93.52%;
    left: 50%;
  }

  .mod8 {
    position: absolute;
    z-index: 10;
    top: 93.52%;
    right: 3.33%;
  }

  .coloursLogo {
    width: 306px;
    height: 50px;
    position: absolute;
    z-index: 15;
    top: 2.78%;
    left: 2.6%;
  }

  .fourColumn {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    z-index: 8;
    text-align: center;
  }

  .twoColumnBack {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    background-image: url("../../img/背景.png");
    background-repeat: no-repeat;
    background-size: 100% 33.33%;
    background-position: bottom right;
    z-index: 5;
  }

  .titleTxt {
    position: absolute;
    top: 23.89%;
    right: 45.83%;
    width: 160px;
    height: 56px;
    font-size: 40px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #143C78;
    line-height: 56px;
    opacity: 0;
  }

  .fourColumn2 {
    position: absolute;
    width: 1152px;
    height: 340px;
    top: 55.93%;
    left: 0;
    right: 0;
    margin: auto;
    opacity: 0;
  }

  .contactDiv {
    width: 368px;
    height: 144px;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid rgba(151, 151, 151, 0.2);
  }

  .contactDiv:hover {
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
  }

  .contactDiv0 {
    position: absolute;
    top: 0;
    left: 0;
  }

  .contactDiv1 {
    position: absolute;
    top: 0;
    left: 392px;
  }

  .contactDiv2 {
    position: absolute;
    top: 0;
    right: 0;
  }

  .contactDiv3 {
    top: 168px;
    left: 0;
    position: absolute;
  }

  .contactDiv4 {
    position: absolute;
    top: 168px;
    left: 392px;
  }

  .contactDiv5 {
    position: absolute;
    top: 168px;
    right: 0;
  }

  .contactDiv6 {
    position: absolute;
    width: 1152px;
    height: 64px;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid rgba(151, 151, 151, 0.2);
    top: 336px;
    right: 0;
  }

  .contactDiv6:hover {
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
    top: 338px;
  }

  .recruitTxt {
    position: absolute;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 32px;
    top: 32px;
    left: 40px;
  }

  .recruitTxt2 {
    position: absolute;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    line-height: 24px;
    left: 40px;
    top: 88px;
  }

  .recruitTxt3 {
    position: absolute;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    line-height: 24px;
    top: 31.25%;
    left: 3.47%;
  }

  .recruitTxt4 {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #143C78;
    line-height: 24px;
  }

  .Zhaopin {
    position: absolute;
    top: 31.25% !important;
    right: 19.44% !important;
  }

  .arrowMark1 {
    position: absolute;
    width: 1.39%;
    height: 25%;
    background: #FFFFFF;
    line-height: 24px;
    top: 37.5%;
    right: 16.67%;
  }

  .arrowMark2 {
    position: absolute;
    width: 1.39%;
    height: 25%;
    background: #FFFFFF;
    line-height: 24px;
    top: 37.5%;
    right: 3.47%;
  }

  .prospect {
    position: absolute;
    top: 31.25% !important;
    right: 6.25% !important;
  }

  .threeColumnImg0 {
    position: absolute;
    width: 35.1%;
    height: 100%;
    top: 0px;
    left: 100%;
    z-index: 5;
  }

  .shapeImg1 {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
  }

  .threeColumnImg1 {
    position: absolute;
    height: 100%;
    width: 37.19%;
    top: 0px;
    left: 100%;
    z-index: 10;
  }

  .shapeImg2 {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
  }

  .threeColumnImg2 {
    position: absolute;
    width: 35.42%;
    height: 100%;
    top: 0px;
    left: 100%;
    z-index: 15;
  }

  .shapeImg3 {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
  }

  .svgImg1 {
    width: 100px;
    height: 100px;
    z-index: 30;
    margin-left: 110px;
    margin-top: 10px;
  }

  .svgImg2 {
    width: 100px;
    height: 100px;
    z-index: 30;
    margin-top: 10px;
    margin-left: 110px;
  }

  .svgImg3 {
    z-index: 30;
    width: 100px;
    height: 100px;
    margin-top: 10px;
    margin-left: 110px;
  }

  .solveTxt {
    position: absolute;
    top: 13.89%;
    left: 45.83%;
    font-size: 40px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 56px;
    z-index: 30;
    animation: solveTextMove 1s linear forwards;
    opacity: 0;
  }

  .solveTxt2 {
    font-size: 30px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 38px;
    z-index: 30;
  }

  .solveTxt3 {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.85);
    line-height: 32px;
    z-index: 30;
    width: 300px;
  }

  .solvePlace1 {
    margin-top: 40px;
    margin-left: 70px;
  }

  .solvePlace2 {
    margin-top: 24px;
    margin-left: 10px;
  }

  .solvePlace3 {
    margin-top: 40px;
    margin-left: 70px;
  }

  .solvePlace5 {
    margin-top: 40px;
    margin-left: 49px;
  }

  .contactUsTxt {
    position: absolute;
    top: 23.89%;
    left: 45.83%;
    font-size: 40px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #143C78;
    line-height: 56px;
    z-index: 5;
    opacity: 0;
  }

  .twoColumnImg {
    width: 100%;
    height: 33.33%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 5;
  }

  .twoColumnImg2 {
    position: absolute;
    top: 260px;
    left: 720px;
    font-size: 30px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #143C78;
    line-height: 38px;
    z-index: 200;
    opacity: 0;
  }

  .twoColumnImg2s {
    position: absolute;
    top: 160px;
    left: 820px;
    font-size: 30px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #143C78;
    line-height: 38px;
    z-index: 200;
    opacity: 0;
  }

  .twoColumnImg3 {
    position: absolute;
    top: 362px;
    left: 720px;
    width: 560px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 40px;
    z-index: 50;
    opacity: 0;
  }

  .twoColumnImg3s {
    position: absolute;
    top: 262px;
    left: 820px;
    width: 560px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 40px;
    z-index: 50;
    opacity: 0;
  }

  .twoColumnImg4 {
    position: absolute;
    width: 640px;
    height: 600px;
    top: 30%;
    left: 0px;
    z-index: 30;
    opacity: 0;
  }

  .twoColumnLogo {
    position: absolute;
    width: 100px;
    height: 100px;
    z-index: 32;
    top: 138px;
    left: 314px;
    opacity: 0;
  }

  .divCircle1 {
    position: absolute;
    top: 201px;
    left: 60px;
    width: 104px;
    height: 104px;
    background: rgba(20, 60, 120, 0.3);
    border-radius: 50%;
    z-index: 35;
    opacity: 0;
  }

  .divBow1 {
    position: absolute;
    width: 434px;
    height: 323px;
    left: 103px;
    top: 138.5px;
    opacity: 0;
  }

  .divCircle2 {
    position: absolute;
    top: 81px;
    left: 177px;
    width: 102px;
    height: 102px;
    background: rgba(20, 60, 120, 0.3);
    border-radius: 50%;
    z-index: 35;
    opacity: 0;
  }

  .divCircle3 {
    position: absolute;
    top: 165px;
    left: 401px;
    width: 62px;
    height: 62px;
    background: rgba(20, 60, 120, 0.3);
    border-radius: 50%;
    z-index: 35;
    opacity: 0;
  }

  .divCircle4 {
    position: absolute;
    top: 193px;
    left: 433px;
    width: 62px;
    height: 62px;
    background: rgba(20, 60, 120, 0.3);
    border-radius: 50%;
    z-index: 35;
    opacity: 0;
  }

  .divCircle5 {
    position: absolute;
    top: 350px;
    left: 401px;
    width: 46px;
    height: 46px;
    background: rgba(20, 60, 120, 0.3);
    border-radius: 50%;
    z-index: 35;
    opacity: 0;
  }

  .divCircle6 {
    position: absolute;
    top: 282px;
    left: 133px;
    width: 70px;
    height: 70px;
    background: rgba(20, 60, 120, 0.3);
    border-radius: 50%;
    z-index: 35;
    opacity: 0;
  }

  .divCircle7 {
    position: absolute;
    top: 350px;
    left: 509px;
    width: 70px;
    height: 70px;
    background: rgba(20, 60, 120, 0.3);
    border-radius: 50%;
    z-index: 35;
    opacity: 0;
  }

  .divCircle8 {
    position: absolute;
    top: 418px;
    left: 209px;
    width: 70px;
    height: 70px;
    background: rgba(20, 60, 120, 0.3);
    border-radius: 50%;
    z-index: 35;
    opacity: 0;
  }

  .twoColumnImg5 {
    position: absolute;
    width: 640px;
    height: 600px;
    top: 0px;
    left: 20%;
    z-index: 30;
    opacity: 0;
  }

  .hover {
    position: absolute;
    width: 6px;
    height: 6px;
    top: 16.57%;
    left: 49.61%;
    background: #FFFFFF;
    border-radius: 50%;
  }

  .hover:after {
    display: block;
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    top: -20px;
    left: -20px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
  }

  .hover:before {
    display: block;
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    top: -18px;
    left: -19px;
    animation: turn 2s linear infinite;
    border-left: 2px solid #FFFFFF;
    border-radius: 50% 0 0 50%;
  }

  .textColumn1 {
    position: absolute;
    top: 30.8%;
    left: 19.29%;
    width: 320px;
    height: 350px;
  }

  .textColumn2 {
    position: absolute;
    top: 30.8%;
    left: 23.81%;
    width: 320px;
    height: 350px;
  }

  .textColumn3 {
    position: absolute;
    top: 30.8%;
    right: 27.94%;
    width: 320px;
    height: 350px;
  }

  .threeColumn {
    position: absolute;
    bottom: 0;
    display: block;
    content: '';
    height: 0;
    width: 0;
    margin: auto;
    background-color: #143C78;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    z-index: 2;
  }

  .district {
    position: absolute;
    width: 403px;
    height: 302px;
    top: 78.33%;
    left: 50%;
    opacity: 0;
  }

  .backgroundFour {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 480px;
  }

  .atlasDiv {
    position: absolute;
    width: 43.75%;
    height: 200%;
    top: -50%;
    left: -20%;
    overflow: hidden;
    border-radius: 0 50% 50% 0;
  }

  .atlasDiv2 {
    position: absolute;
    width: 1080px !important;
    height: 1080px;
    top: 0;
    left: -168px;
    bottom: 0;
    margin: auto;
  }

  .dotImages {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 40;
  }

  /*.dotImages:hover{*/
  /*  transform: rotateX(20deg) rotateY(30deg);*/
  /*  transition: all 0.2s;*/
  /*}*/
</style>
